import Masonry from "masonry-layout";

export default {
    masonry: null,
    loading: null,
    load() {
        // use a clearing timeout to manage the calling of this function so it is not called too many times
        // in succession and lock client up
        window.clearTimeout(this.loading);
        // Only try to load again after previous one has finished
        this.loading = setTimeout(() => {
            if (!!document.querySelector('.c-cards')) {
                let homeTabs = !!document.querySelector('.c-home-tabs');
                let cardsContainer;
                if (homeTabs) {
                    cardsContainer = document.querySelector('.c-home-tabs:not(.hidden) .c-cards');
                } else {
                    cardsContainer = document.querySelector('.c-cards');
                }
                this.masonry = new Masonry(cardsContainer, {
                    percentPosition: true,
                    itemSelector: '.c-card'
                });
            }
        }, 66);
    },
    images() {
        setTimeout(() => {
            let images = document.querySelectorAll('.c-card__media img:not(.play-icon)');
            for (let i = 0; i < images.length; i++) {
                if (images[i].complete) {
                    this.load();
                } else {
                    images[i].onload = () => {
                        this.load();
                    };
                }
            }
        }, 100);
    }
};
